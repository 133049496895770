/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "SC2 Lounge was the first personal site I made and launched. When watching Starcraft 2 and e-sports videos on YouTube, I often became aggravated when the first video out of a series would end and the next would not automatically start. SC2Lounge was my solution. After over a year of repeatedly coding and re-coding it, it launched."), "\n", React.createElement(_components.p, null, "I rewrote all of the code on this site three times before being happy with how the site functioned. The most noticeable improvement was the benchmarks in the updating process of the database. I started using PHP to load the user data from YouTube and Zend Lucene to build the search index. The performance was acceptable the first time; however, once that virgin cloud lifted, I started looking for another alternative. After some research, I decided that using Java and Apache Lucene would work the best. The only problem, I needed to learn Java. I took the time to learn the language, which was a lot like Actionscript 3, and after writing the script that did the exact thing PHP was doing, I couldn't have been happier with the result. The performance improvements when building the search index were incredible. I got it working on the first try, and I had to watch the directories to ensure it was working correctly due to the speed. In indexing 20,000 database entries, PHP took ~5 minutes, while Java took ~9 seconds. No brainer, right?!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
